import { useState, useMemo, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { addDays } from "date-fns";

import { selectUserStyles } from "../../../public/stylesheets/selectStyles";
import { MdArrowBackIosNew, MdOutlineGroup } from "react-icons/md";
import { FaEye } from "react-icons/fa";

import Table from "../../../../components/Table";
import DateRange from "../../../../components/DateRange";

import { formatDate, monthOptions } from "../../../../utils/helper";
import serialise, { generateSelectData, generateSelectUser } from "../../../../utils/serialiseResponse";

import { viewUsersForUtilities } from "../../../../services/utilities";
import { clearTourProgram, setTourProgram } from "../../../../reducers/tpReducer";
import { viewTPAggr } from "../../../../services/tourProgram";

const TourUtility = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const loggedIn = useSelector(({ user }) => user);
  const tpData = useSelector(({ tp }) => tp);
  const [aggregatedTp, setAggrTP] = useState(false) ;
  const [employees, setEmployees] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  const [dateRange, setDateRange] = useState({startDate: new Date(),endDate: addDays(new Date(), 7),});
  const [startMonth, setStartMonth] = useState(dateRange.startDate.getMonth());
  const [showStatus, setShowStatus] = useState(false);
  const [reports, setReports] = useState([]) ;
  const [employeeDataForExcel, setEmployeeDataForExcel] = useState();
  const employeeSelect = useMemo(
    () => generateSelectUser(employees, "fullName"),
    [employees]
  );
  const employeeData = useMemo(
    () => generateSelectData(employees, "fullName"),
    [employees]
  );

  useEffect(() => {
    try {
      let tempdata = employeeData.filter((empData) => {
        if (empData.label === selectedUser.label) return true;
      })
      setEmployeeDataForExcel(tempdata[0]);
    } catch (error) {
      console.log(error);
    }
  }, [selectedUser]);

  useEffect(() => {
    setStartMonth(dateRange.startDate.getMonth())
  }, [dateRange])

  const columns = useMemo(
    () => [
      {
        Header: "Date",
        accessor: "date",
        minWidth: 130,
        maxWidth: 130,
        Cell: (props) => {
          return <span>{formatDate(props?.row?.original?.date)}</span>;
        },
      },
      {
        Header: "Name",
        accessor: "submitter.firstName",
        Cell: (props) => {
          
         return <span>
            {/* {selectedUser?.value
               ? selectedUser?.label
               : */}
               {props?.row?.original?.submitter?.firstName + " " + props?.row?.original?.submitter?.lastName }
               {/* loggedIn?.user?.firstName + " " + loggedIn?.user?.lastName} */}
          </span>
      },
      },
      {
        Header: "Area Type",
        accessor: "areaType",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 70,
        maxWidth: 70,
      },
      {
        Header: "Areas",
        accessor: "areas",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) =>
          props?.row?.original?.workAreas.map((e, i) => (
            <span key={i}>
              {e.location.label} <br />
            </span>
          )),
      },
      {
        Header: "Old Areas",
        accessor: "aareas",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) =>
          props?.row?.original?.oldWorkAreas?.map((e, i) => (
            <span key={i}>
              {e.location.label} <br />
            </span>
          )),
      },
      {
        Header:"Edited",
        accessor:"",
        minWidth: 100,
        maxWidth: 100,
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => props?.row?.original?.edited ? <span className="text-warning">Edited</span> : <span className="text-info">New</span>  
      },
      {
        Header:"Remarks",
        accessor:"remarks",
        minWidth: 100,
        maxWidth: 100,
        wordWrap:"break-word",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header:"Activity",
        accessor:"",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 100,
        maxWidth: 100,
        Cell:(props) =>{
          return props?.row?.original?.activity ? 
          props?.row?.original.activity :
          <span>--</span>
        }
      },
      {
        Header:"Worked with",
        accessor:"",
        minWidth: 150,
        maxWidth: 190,
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          return props?.row?.original?.workedWith?.map((el) => (
            <li key={el?._id}>
              {`${el?.firstName} ${el?.lastName}`}
              <br />
            </li>
          ));
        },
      },
      {
        Header: showStatus ? "Status" : "View Details",
        accessor: "",
        Cell: (props) => {
          if (!showStatus) {
            return (
              <Link
                to={`${props?.row?.original?._id}`}
                state={props?.row?.original}
                className="util-table-view-details-link"
              >
                <FaEye className="icon-color-tertiary" />
              </Link>
            );
          } else {
            let status = props?.row?.original?.isApproved;
            let statusText = "";
            let statusColor = "";
            switch (status) {
              case 0:
                statusText = "Pending";
                statusColor = "rgb(244, 187, 68)";
                break;
              case 1:
                statusText = "Approved";
                statusColor = "green";
                break;
              case -1:
                statusText = "Rejected";
                statusColor = "red";
                break;
              default:
                break;
            }
            return (
              <span
                style={{
                  backgroundColor: statusColor,
                  padding: "0.2em 0.5em",
                  borderRadius: "4px",
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                {statusText}
              </span>
            );
          }
        },
        minWidth: 100,
        maxWidth: 100,
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    [showStatus]
  );

  const columns2 = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "user",
      },
      {
        Header: "Area Type",
        accessor: "areaType",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 70,
        maxWidth: 70,
      },
      {
        Header: "Areas",
        accessor: "workingArea",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Frequency",
        accessor: "count",
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    []
  );

  const handleAllVisit = (e) => {
    e.preventDefault();
    setShowStatus(!showStatus);
  };

  useEffect(() => {
    try {
      viewUsersForUtilities().then((res) => {
        setEmployees(res?.data);
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    
    let rdata = {
      selectedUser: selectedUser?.value,
      startDate: new Date(dateRange?.startDate).getTime(),
      endDate: new Date(dateRange?.endDate).getTime(),
      status:showStatus ? true : null,
    };

    const aysncFetch = async () => {
      try {
        if(aggregatedTp){
          const {data} = await viewTPAggr(rdata);
          setReports(data) ;
        }else dispatch(setTourProgram(rdata));
      } catch (err) {
        console.log(err);
      }
    };

    aysncFetch();

    
    return () => {
      dispatch(clearTourProgram());
    };
  }, [showStatus, selectedUser, dateRange, dispatch, aggregatedTp]);

  const tableData = useMemo(() => serialise(tpData?.data), [tpData?.data]);
  
  function compareDates(a, b) {
    const dateA = new Date(a.date);
    const dateB = new Date(b.date);
    return dateA - dateB;
  }
  const Checkbox = ({ label, value, onChange }) => (
    <div className="d-flex align-items-center">
      <div
        className="form__control px-5"
        style={{ width: "fit-content", color: "#c7c4e9" }}
      >
        <span className="">{label}</span>
        <label className="toggle-label mx-3">
          <input
            type="checkbox"
            name="reportType"
            checked={value}
            onChange={onChange}
          />
          <span />
        </label>
        
      </div>
    </div>
  );
  // Sort the tableData array using the compareDates function
  tableData.sort(compareDates);

  return (
    <div className="expense">
      <div className="row">
      <div className="d-flex justify-content-between">
        <h2
          className="web-app__heading"
          style={{ display: "flex", alignItems: "center" }}
        >
          <MdArrowBackIosNew
            style={{ cursor: "pointer" }}
            onClick={() => navigate(-1)}
          />
          <span style={{ display: "inline-block", margin: "0 1rem" }}>
            Tour Program Reports
          </span>
        </h2>
          {
            <div className="d-flex w-70 justify-content-between">
              <div className="border-end border-3 border-success">
                <Checkbox
                  label="Frequency Report"
                  value={aggregatedTp}
                  onChange={() => setAggrTP(!aggregatedTp)}
                />
              </div>
              {/* <div className="border-end border-3 border-success">
                <Checkbox
                  label="Detailed Report"
                  value={isDetailedReport}
                  onChange={() => handleCheckboxChange("detailed")}
                />
              </div> */}
              {/* <div>
                <Checkbox
                  label="Worked With Report"
                  value={isWorkedWithReport}
                  onChange={() => handleCheckboxChange("workedWith")}
                />
              </div> */}
            </div>
          }
        </div>

        <div className="util-tp-query d-flex justify-content-between">
          <div className="d-flex">
            {loggedIn?.user?.des < 121 ||
            loggedIn?.user?.designation?.priority > 1 ||
            loggedIn?.user?.isAdmin ? (
              <div className="util-tp-filter me-5">
                <p className="util-select-user__label">Select User</p>
                <div className="util-select-user">
                  <MdOutlineGroup className="util-select-user__icon" />
                  <Select
                    styles={selectUserStyles}
                    options={employeeSelect}
                    placeholder="Select User"
                    className="mt-3"
                    value={selectedUser}
                    onChange={(e) => setSelectedUser({ ...e })}
                  />
                </div>
              </div>
            ) : null}
          </div>
        </div>

        <div className="date-range-container my-3">
          <DateRange setDateRange={setDateRange} />
        </div>
        <div className="filter-table">
          { tableData.length > 0 && (
            <div className="util-tp-query d-flex">
              <div className="d-flex utility-cards__heading">
                <h2 className="web-app__heading mt-4 mx-5">
                  Showing ({aggregatedTp ? reports?.length : tableData.length}) Entries
                </h2>
                <div className="edit-form-button-container">
                  <button
                    className={`p-3 button-blue-gradient`}
                    onClick={(e) => handleAllVisit(e)}
                  >
                    {showStatus ? "Close Status" : "View Status"}
                  </button>
                </div>
              </div>
            </div>
          )}
          { tableData.length > 0 || reports.length > 0 ? (
            <Table defaultPageSize={50} columns={aggregatedTp ? columns2 : columns} data={aggregatedTp ? reports : tableData} empl={selectedUser ? employeeDataForExcel : loggedIn?.user} fileSrc="Tour Program"
            month={monthOptions.at(startMonth)} 
            />
          ) : <h3 className="no-results-message">No results found</h3>}
        </div>
      </div>
    </div>
  );
};

export default TourUtility;
